import React from 'react';
import { graphql } from "gatsby";
import SEO from "../../components/seo/Seo";
import Header from "./../../components/headers/Header";
import Footer from "../../components/layouts/Footer";
import MainRowDescription from "../../components/main-row/MainRowDescription";
import StaticMainRowGallery from "../../components/main-row/StaticMainRowGallery";
import CallToAction from "../../components/layouts/CallToAction";
import GoodToKnow from "../../components/main-row/GoodToKnow";
import MainRowHeader from "../../components/main-row/MainRowHeader";

function GlavniRov({data}) {
    return (
        <>
            <SEO title="Glavni rov" description="Z obiskom Glavnega rova Rudnika Sitarjevec Litija boste tako blizu naravi in neskončnemu nastajanju večnih naravnih procesov, da tega ne boste le opazovali, ampak se boste čutili del procesa samega. Zaradi doživljanja svetlobe in teme, tišine in zvokov v rudniškem podzemlju, vonja železa ter premikanja zraka - morda bolj intenzivno kot kadarkoli doslej." />
            <Header />
            <MainRowHeader/>
            <MainRowDescription/>
            <GoodToKnow/>
            <StaticMainRowGallery imagesData={data.source.edges}/>
            <CallToAction/>
            <Footer/>
        </>
    );
}

export const pageQuery = graphql`
    query ImagesForMainRowGallery {
        source: allFile(filter: {relativePath: {regex: "rudnik/glavni-rudnik-galerija/"}}) {
            edges {
                node {
                    childImageSharp {
                        thumb: gatsbyImageData(
                            width: 200
                            height: 200
                            placeholder: BLURRED
                        )
                        full: gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;

export default GlavniRov;