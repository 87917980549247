import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function MainRowDescription(props) {
    return (
        <div>
            <div className="about-wrapper ptb--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12 col-xl-6 order-1 mt-4 order-lg-0">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/glavni-rov-sitarjevskega-rudnika.jpg"
                                    alt="Glavni rov v Rudniku Sitarjevec"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6 order-0 order-lg-1">
                            <div className="about-inner inner p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Vodeni ogledi</h2>
                                    <p className="description">Od 22.4.2021 dalje je za obiskovalce odprt spodnji del Rudnika Sitarjevc Litija. Do Glavnega
                                        rova se boste spustili po povsem na novo urejenem vpadniku. Znašli se boste v avtentičnem
                                        rudniškem okolju. To je delno odprto tako v višino kot v globeli, delno v udobno razširjene
                                        rudniške rove.</p>
                                    <p className="description"> Po živobarvnem limonitnem blatu se boste mimo barvno še bolj zanimivih
                                        rudnin na stenah sprehodili do znamenitega, največjega odkritega limonitnega kapnika v
                                        rudniku. Ob poti boste občudovali dediščino industrijske arhitekture, na koncu pa nagrajeni s
                                        prečudovitim pogledom na limonitne špagete – izjemno tanke kapniške strukture.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pb--160">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-12 col-lg-10 col-xl-9 col-md-12 text-center text-md-left">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">Čaroben svet litijskega podzemlja</h2>
                                    <p className="description">Podzemlje Rudnika Sitarjevec Litija je zelo ranljiv prostor. Povsem od blizu boste opazovali
                                        neprestano in intenzivno nastajanje znamenitih limonitnih kapniških struktur in limonitnega
                                        blata, zaradi katerega je rudnik Sitarjevec tako zelo barvit in unikaten. Prav tako nenehno
                                        poteka kristalizacija sadre. Krhki igličasti kristali, ki se tvorijo na stropu opuščenih rudniških
                                        rovov, navdihujejo z eleganco svetlobnih odbojev, prav tako kapljice vode, ki očarajo z
                                        bisernimi svetlobnimi odsevi.</p>
                                    <p className="description">
                                        Z obiskom Glavnega rova Rudnika Sitarjevec Litija boste tako
                                        blizu naravi in neskončnemu nastajanju večnih naravnih procesov, da tega ne boste le
                                        opazovali, ampak se boste čutili del procesa samega. Zaradi doživljanja svetlobe in teme,
                                        tišine in zvokov v rudniškem podzemlju, vonja železa ter premikanja zraka - morda bolj
                                        intenzivno kot kadarkoli doslej.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainRowDescription;